import React from "react"
import { Link } from "gatsby"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import title_image from '../../images/titles/modern_software_delivery.jpg'
import person from '../../images/person.png'

const pageTitle = "Modern Software Delivery";
const pageSlug = "modern-software-delivery";
class CloudComputing extends React.Component{
  render(){
    return(
      <Layout pageType="technology-services">
      <SEO title={pageTitle} />
      <section className="section page-title" style={{ backgroundImage: `url(${title_image})`}}>
      <div className="overlay"></div>
        <div className="container">
            <h1 className="title">{pageTitle}</h1>
            <h6 className="subtitle">Quickly realize the value present in your technology investment while remaining 
            competitive in an ever-changing and evolving marketplace, with a Modern Software Delivery strategy.</h6>
        </div>
      </section>

      <section id="p_technology_services" className="section static-page page-content">
        <div className="container">
            <div className="columns">
                <div className="column is-12 content-area">
                    <h3>
                    A Business Strategy That Harnesses Innovation.
                    </h3>
                 <p>Today's competitive market is continuously changing; it requires an adaptable and agile business that 
                     can deliver new products faster than was ever needed before. Advancements in technology are fueling 
                     innovation in competitors while also enabling industry disruptors to enter the marketplace. For 
                     companies to remain competitive, they must understand that change is constant. Businesses cannot 
                     solely rely on technology or agile principles to provide all the answers, regular new ideas and 
                     development must be harnessed.</p>
                    <p>Becoming an innovative business requires a relevant business strategy that's comprehensive and 
                        transformative. With such an approach, an organization can continuously create and capture these 
                        ideas and rapidly bring them to the market</p>
                    <span className="break"></span>
                    <h4>Parts of a Modern Software Delivery System</h4>
                    <p>Nuerex's Modern Software Delivery strategy focuses on transformative opportunities across four 
                        fundamental areas that affect businesses</p>
                    <h5>Principles</h5>
                    <p>Every organization can benefit from applying principles of Lean and agility to their technology 
                        delivery system. These principles can include Product Development, Business Agility, Lean-Agile 
                        Methodologies, Integrated Feedback, Visible Work and Work in Progress Management. Through applying 
                        these principles to software development capabilities, a business can be aligned with the current 
                        capabilities of technology.</p>
                    <h5>People</h5>
                    <p>Having strategic partnerships between your IT and business leadership & functions allows for rapid
                         innovation to take place in your organization. The collaborative growth this creates can be 
                         applied throughout all levels of the organization to develop highly efficient and optimized 
                         teams. These high performing teams are collaborative and accelerated by technology platforms</p>
                    <h5>Platforms</h5>
                    <p>The fastest way for an organization to increase its productivity is to implement cloud technology 
                        platforms that enable scalability and innovation. Secure and accessible cloud platforms are the 
                        accelerators that empower high performing teams to deliver more value consistently.</p>
                    <h5>Practices</h5>
                    <p>Moving an idea to production as quickly as possible is essential. DevOps can enable your organization 
                        to see improved deployment capabilities while maintaining quality and reliability. DevOps capabilities
                         continue to mature once inside an organization. This organization will then become better able to 
                         identify any opportunities for effective workflow automation</p>
                    <span className="break"></span>
                    <h4>The Benefits of a Modern Software Delivery Strategy</h4>
                    <p>When high performing teams apply DevOps practices, Agile principles and use cloud platforms, their 
                        production is increased. This allows for rapid value delivery without sacrificing quality and 
                        reliability.  Industries studies report that applying a Modern Software Delivery strategy can 
                        bring the following benefits:</p>
                    <p><i>Sources: DORA State of DevOps report 2018, State of DevOps report 2017, Accelerating Velocity 
                        and Customer Value</i></p>
                   <ul>
                       <li>Market capitalization growth of 50% higher than industry competitors</li>
                       <li>Mature teams deliver code to production 46 times more often than traditional IT organizations</li>
                       <li>Teams use cloud platforms for 83% of their applications</li>
                       <li>Business growth is improved by 63% when adding Modern Software Delivery Practices to an agile environment</li>
                       <li>Technology delivery speed is improved by 37%, employee retention by 77% and customer engagement by a factor of 4</li>
                   </ul>
                   <span className="break"></span>
                   <p>Not sure where to start? Together we can find a solution</p>
                   <Link to="/contact-us" className="button green">Contact us today</Link>
                   <span className="break"></span>
                   <h4>Customer Application Development & Implementation</h4>
                  <p>Our experts don't just match solutions with technology requirements. They deliver software in a modern and personalized
                       way that helps organizations bring their ideas to the market more efficiently. Our Modern Software Delivery strategy 
                       empowers an organization to deliver more value to the market faster while maintaining quality. Whether it may be broader
                        transformation work or targeting specific problems and incremental challenges.</p>
                <span className="break"></span>
                <h3 className="intext">Our custom applications are fast, simple to use, and easy to maintain.</h3>
                <span className="break"></span>
                <p>We have firsthand experience of the current technical landscape and ensure our applications are built with the latest 
                    technologies. Giving them a diverse array of capabilities. This enables our team to help your organization make the 
                    right technology decisions. Our services include:</p>
                <h5>Oversight & Governance</h5>
                <p>All organizations need a plan that can provide a balanced approach to their operations. We encourage and facilitate 
                    decision making related to the specific technology and applications within the business.</p>
                <h5>Assessment & Strategy</h5>
                <p>A business's need for technology solutions is based on their expected value and organizational readiness. Our experts 
                    can assess your entire IT organization or focus on specific areas or teams to identify and prioritize process 
                    improvement initiatives. We can provide you with a Roadmap for improvement, Capability Maturity Analysis, and 
                    ensure that the situation can give repeatable growth.</p>
                <h5>Tool Selection & Implementation</h5>
                <p>Having the right technology platforms can help your business accelerate its internal processes to deliver more value 
                    at a faster rate. We can help identify and evaluate the right set of tools that are needed based on your 
                    organizational needs and readiness. Our practices ensure a long-running, maintainable solution that can continue 
                    to solve customer needs for years to come</p>
                <h5>Coaching & Teaching</h5>
                <p>Competency and capability are two widely different terms. We strive to help our clients develop their abilities by 
                    using our subject matter experts. We produce high performing teams that have an integrated People-Process-Technology 
                    Approach. We give all relevant knowledge to an internal team allowing for greater sustainability.</p>
                    <h5>Agile</h5>
                <p>Agile practices can turn an intricate project into a manageable solution. We help you improve transparency, 
                    collaboration, and delivery through agile development</p>
                <ul>
                    <li>Agile training & coaching</li>
                    <li>Workflow Management</li>
                    <li>Agile methodology improvement and implementation</li>
                </ul>
                <h5>DevOps</h5>
                <p>DevOps is more than just continuous delivery software. It serves as a way to continuously and reliably 
                    deliver software. We can help you implement a DevOps engineering solution to accelerate your delivery 
                    process.</p>
                <ul>
                    <li>Tool Optimization</li>
                    <li>Instrumented Processes</li>
                    <li>Modular Architecture</li>
                    <li>Workflow Automation</li>
                </ul>
                <h5>Testing</h5>
                <p>Quality assurance and constant improvement are critical steps in accelerating your delivery efforts. 
                    Our testing services can provide you with the ability to automate processes and shorten feedback times.</p>
                <h5>Cloud</h5>
                <p>The cloud can serve as an accelerator to your current software delivery services. The cloud is a platform, 
                    not just hardware somewhere else. Our experts can help you optimize and personalize cloud platforms for your 
                    specific needs.</p>
                <span className="break"></span>
                    <div className="contact-box">
                    <h3>Eager to learn more about our technology expertise?</h3>  
                    <span className="break"></span>
                    <div className="button-area">
                        <Link to="/contact-us" className="button default">Contact us</Link>
                    </div>
                    </div>
                </div>
              {/*  <div className="column is-3 sidebar">
                    <div className="person-info">
                        <img src={person} />
                        <div className="inner">
                            <h2 className="name">Greg Klem</h2>
                            <p className="status">Vice President</p>
                            <p>National Practice Lead - Business </p>
                            <p>Consulting Services</p>
                        </div>
                        <p>Not sure where to start, our team is always ready to help.</p>
                        <Link to="/" className="button green">Talk with a professional</Link>
                    </div>
                </div>*/}
            </div>
        </div>
      </section>
      </Layout>
    )
  }
}

export default CloudComputing
